<template>
    <div class="case-container">
        <div class="common-first-part">
            <div>
                <p class="">实施案例</p>
            </div>
            <img :src="require('@/assets/images/index/project-1.png')" alt="">
        </div>
        <div class="case-part2">
            <p class="common-title">项目产出</p>
            <span>清华社学前教育专业解决方案面向本科、高职、中职等开设学前教育、</span>
            <span>幼儿健康与管理、婴幼儿保育等专业的院校提供服务。</span>
            <span>适用于高校申报虚拟仿真项目、虚拟仿真实训室/智慧教室建设、理虚</span>
            <span>实一体化实训基地建设等经费支出。</span>
        </div>
        <div class="case-itemlists">
            <div class="case-items">
                <img class="case-imgs" :src="require('@/assets/images/index/case-1.png')" alt="">
                <div class="case-infos">
                    <p class="school">首都师范大学</p>
                    <p class="project">学前教育学院虚拟仿真资源建设项目</p>
                    <p class="year">2020年</p>
                </div>
            </div>
            <div class="case-items">
                <img class="case-imgs" :src="require('@/assets/images/index/case-2.png')" alt="">
                <div class="case-infos">
                    <p class="school">咸阳职业技术学院</p>
                    <p class="project">数字课程出版项目</p>
                    <p class="year">2021年</p>
                </div>
            </div>
            <div class="case-items">
                <img class="case-imgs" :src="require('@/assets/images/index/case-3.png')" alt="">
                <div class="case-infos">
                    <p class="school">湖南娄底幼儿师范学校</p>
                    <p class="project">实训室建设项目</p>
                    <p class="year">2021年</p>
                </div>
            </div>
        </div>
        <div class="pics-lists">
            <img class="case-pics" :src="require('@/assets/images/index/case-01.png')" alt="">
            <img class="case-pics" :src="require('@/assets/images/index/case-02.png')" alt="">
            <img class="case-pics" :src="require('@/assets/images/index/case-03.png')" alt="">
            <img class="case-pics" :src="require('@/assets/images/index/case-04.png')" alt="">
        </div>
        <p class="common-title align-center">多家高校与清华社进行项目合作</p>
        <div class="case-itemlists coop-caseitemlists">
            <div class="case-items new-case-items" v-for="(item, index) in lists" :key="index">
                <div class="origin-items">
                    <div class="origin-items-banner"><img class="new-imgs" :src="item.banner" alt=""></div>
                    <div class="case-infos new-case-infos">
                        <p class="school" :style="{'margin-top': index===9?'-8px':'0'}">{{item.school}}</p>
                        <p class="project new-project" :style="{'margin-top': index===9?'-8px':'0'}">{{item.infos}}</p>
                        <p class="project new-project" style="width: calc( 100% + 20px );">{{item.infosTwo}}</p>
                        <p class="project new-project">{{item.infosThree}}</p>
                    </div>
                </div>
                <div class="hover-items">
                    <div class="hover-items-header">
                        <img class="hover-items-header-imgs" :src="item.hoverBanner" alt="">
                        <div class="hover-items-header-infos">
                            <p class="hover-items-header-infos-school">{{item.school}}</p>
                            <p class="hover-items-header-infos-project">{{item.infos}}{{item.infosTwo}}{{item.infosThree}}</p>
                        </div>
                    </div>
                    <img class="hover-items-img" :src="item.wxImg" alt="">
                    <p class="hover-items-tips">添加微信了解更多</p>
                </div>
            </div>
            <div class="empty-items" style="width: 360px;"></div>
            <div class="empty-items" style="width: 360px;"></div>
            <div class="empty-items" style="width: 360px;"></div>
        </div>
    </div>
</template>
<script>
export default {
    components: {},
    props: {},
    data() {
        return {
            lists: [
                {
                    index: 1,
                    banner: require('@/assets/images/case/1.png'),
                    hoverBanner: require('@/assets/images/case/1.png'),
                    school: '中科院心理学研究所',
                    infos: '儿童发展心理学综合实训',
                    infosTwo: '数字资源系统',
                    infosThree: '',
                    wxImg: require('@/assets/images/case/屈老师.jpeg'),
                    wxInfo: '添加微信了解更多',
                }, {
                    index: 2,
                    banner: require('@/assets/images/case/17.png'),
                    hoverBanner: require('@/assets/images/case/17.png'),
                    school: '首都师范大学',
                    infos: '学前教育学院虚拟仿真实训',
                    infosTwo: '资源',
                    infosThree: '',
                    wxImg: require('@/assets/images/case/屈老师.jpeg'),
                    wxInfo: '添加微信了解更多',
                }, {
                    index: 3,
                    banner: require('@/assets/images/case/21.png'),
                    hoverBanner: require('@/assets/images/case/21.png'),
                    school: '渭南师范学院',
                    infos: '学前教育五大领域',
                    infosTwo: '教学案例库',
                    infosThree: '',
                    wxImg: require('@/assets/images/case/屈老师.jpeg'),
                    wxInfo: '添加微信了解更多',
                }, {
                    index: 4,
                    banner: require('@/assets/images/case/2.png'),
                    hoverBanner: require('@/assets/images/case/2.png'),
                    school: '陕西学前师范学院',
                    infos: '幼儿教师资格证考试',
                    infosTwo: '系统',
                    infosThree: '',
                    wxImg: require('@/assets/images/case/屈老师.jpeg'),
                    wxInfo: '添加微信了解更多',
                }, {
                    index: 5,
                    banner: require('@/assets/images/case/8.png'),
                    hoverBanner: require('@/assets/images/case/8.png'),
                    school: '西安文理学院',
                    infos: '学前教育专业核心课程',
                    infosTwo: '建构与教学改革',
                    infosThree: '',
                    wxImg: require('@/assets/images/case/屈老师.jpeg'),
                    wxInfo: '添加微信了解更多',
                }, {
                    index: 6,
                    banner: require('@/assets/images/case/14.png'),
                    hoverBanner: require('@/assets/images/case/14.png'),
                    school: '山东女子学院',
                    infos: '0-3岁婴幼儿教养类专业',
                    infosTwo: '实训系统',
                    infosThree: '',
                    wxImg: require('@/assets/images/case/屈老师.jpeg'),
                    wxInfo: '添加微信了解更多',
                }, {
                    index: 7,
                    banner: require('@/assets/images/case/3.png'),
                    hoverBanner: require('@/assets/images/case/3.png'),
                    school: '山东管理学院',
                    infos: '学前教育远程师资培训',
                    infosTwo: '系统',
                    infosThree: '',
                    wxImg: require('@/assets/images/case/屈老师.jpeg'),
                    wxInfo: '添加微信了解更多',
                }, {
                    index: 8,
                    banner: require('@/assets/images/case/9.png'),
                    hoverBanner: require('@/assets/images/case/9.png'),
                    school: '石家庄学院',
                    infos: '学前教育实训数字资源库',
                    infosTwo: '系统',
                    infosThree: '',
                    wxImg: require('@/assets/images/case/屈老师.jpeg'),
                    wxInfo: '添加微信了解更多',
                }, {
                    index: 9,
                    banner: require('@/assets/images/case/15.png'),
                    hoverBanner: require('@/assets/images/case/15.png'),
                    school: '衡水学院',
                    infos: '学前教育优质幼儿教师',
                    infosTwo: '培训',
                    infosThree: '',
                    wxImg: require('@/assets/images/case/屈老师.jpeg'),
                    wxInfo: '添加微信了解更多',
                }, {
                    index: 10,
                    banner: require('@/assets/images/case/18.png'),
                    hoverBanner: require('@/assets/images/case/18.png'),
                    school: '咸阳职业技术学院',
                    infos: '学前教育数字课程出版——数字教材',
                    infosTwo: '《学前教育学》通过“十四五”国家规划',
                    infosThree: '教材省级评审',
                    wxImg: require('@/assets/images/case/屈老师.jpeg'),
                    wxInfo: '添加微信了解更多',
                }, {
                    index: 11,
                    banner: require('@/assets/images/case/12.png'),
                    hoverBanner: require('@/assets/images/case/12.png'),
                    school: '福建幼儿师范高等专科学校',
                    infos: '幼儿园环境创设实训',
                    infosTwo: '系统',
                    infosThree: '',
                    wxImg: require('@/assets/images/case/屈老师.jpeg'),
                    wxInfo: '添加微信了解更多',
                }, {
                    index: 12,
                    banner: require('@/assets/images/case/16.png'),
                    hoverBanner: require('@/assets/images/case/16.png'),
                    school: '四川幼儿师范高等专科学校',
                    infos: '幼儿教师资格证考试',
                    infosTwo: '系统',
                    infosThree: '',
                    wxImg: require('@/assets/images/case/屈老师.jpeg'),
                    wxInfo: '添加微信了解更多',
                }, {
                    index: 13,
                    banner: require('@/assets/images/case/4.png'),
                    hoverBanner: require('@/assets/images/case/4.png'),
                    school: '石家庄幼儿师范高等专科学校',
                    infos: '学前教育虚拟仿真实训',
                    infosTwo: '数字资源系统',
                    infosThree: '',
                    wxImg: require('@/assets/images/case/屈老师.jpeg'),
                    wxInfo: '添加微信了解更多',
                }, {
                    index: 14,
                    banner: require('@/assets/images/case/10.png'),
                    hoverBanner: require('@/assets/images/case/10.png'),
                    school: '阜阳幼儿师范高等专科学校',
                    infos: '学前教育专业',
                    infosTwo: '新形态教材出版',
                    infosThree: '',
                    wxImg: require('@/assets/images/case/屈老师.jpeg'),
                    wxInfo: '添加微信了解更多',
                }, {
                    index: 15,
                    banner: require('@/assets/images/case/20.png'),
                    hoverBanner: require('@/assets/images/case/20.png'),
                    school: '吕梁师范高等专科学校',
                    infos: '学前教育“理-虚-实”一体化实训室',
                    infosTwo: '（一日活动、卫生保育）',
                    infosThree: '',
                    wxImg: require('@/assets/images/case/屈老师.jpeg'),
                    wxInfo: '添加微信了解更多',
                }, {
                    index: 16,
                    banner: require('@/assets/images/case/5.png'),
                    hoverBanner: require('@/assets/images/case/5.png'),
                    school: '鹤壁职业技术学院',
                    infos: '学前卫生保育与膳食综合实训',
                    infosTwo: '数字资源系统',
                    infosThree: '',
                    wxImg: require('@/assets/images/case/屈老师.jpeg'),
                    wxInfo: '添加微信了解更多',
                }, {
                    index: 17,
                    banner: require('@/assets/images/case/11.png'),
                    hoverBanner: require('@/assets/images/case/11.png'),
                    school: '娄底幼儿师范学校',
                    infos: '学前教育“理-虚-实”一体化实训室',
                    infosTwo: '（虚拟幼儿园、环创、心理与行为）',
                    infosThree: '',
                    wxImg: require('@/assets/images/case/屈老师.jpeg'),
                    wxInfo: '添加微信了解更多',
                }
            ]
        }
    },
    created() {},
    mounted() {},
    methods: {
        
    }
}
</script>
<style lang='scss' scoped>
.case-container {
    .case-part2 {
        text-align: center;
        padding: 50px 0 30px;
        &>p {
            margin-bottom: 24px;
        }
        &>span {
            display: block;
            font-size: 14px;
            font-weight: 400;
            color: #333333;
            line-height: 26px;
        }
    }
    .case-itemlists {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
        .case-items {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            min-width: 388px;
            width: 388px;
            height: 206px;
            position: relative;
            margin-right: 5px;
            .case-imgs {
                width: 100%;
                height: 100%;
            }
            .case-infos {
                position: absolute;
                bottom: 24px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                width: 100%;
                left: 0;
            }
            .school {
                font-size: 14px;
                font-weight: 400;
                color: #666666;
                line-height: 20px;
            }
            .project {
                font-size: 20px;
                font-weight: 600;
                color: #0768AB;
                line-height: 28px;
                padding: 20px 0 5px;
            }
            .year {
                font-size: 14px;
                font-weight: 400;
                color: #666666;
                line-height: 20px;
            }
        }
        .xian-wenli {
            width: 416px;
            height: 206px;
        }
        .new-case-items {
            width: 360px;
            min-width: 360px;
            height: 220px;
            background: #FFFFFF;
            box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.1);
            cursor: pointer;
            padding: 14px;
            box-sizing: border-box;
            margin-bottom: 25px;
            justify-content: flex-start;
            &:hover {
                .origin-items {
                    display: none;
                }
                .hover-items {
                    display: flex;
                }
            }
            .origin-items {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                &-banner {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    height: 100px;
                }
            }
            .new-imgs {
                width: 100px;
            }
            .new-case-infos {
                position: static;
                .school {
                    padding: 8px 0;
                }
                .new-project {
                    // width: 220px;
                    text-align: center;
                    padding: 0;
                }
            }
            .hover-items {
                display: none;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                width: 100%;
                &-header {
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    align-items: flex-start; 
                    &-imgs {
                        height: 40px;
                        margin-right: 12px;
                    }
                    &-infos {
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-start;
                        align-items: flex-start; 
                        &-school {
                            font-size: 14px;
                            color: #666666;
                            line-height: 20px;
                        }
                        &-project {
                            font-size: 16px;
                            font-weight: 600;
                            color: #0768AB;
                            line-height: 22px;
                            text-overflow: -o-ellipsis-lastline;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            -webkit-line-clamp: 2;
                            -webkit-box-orient: vertical;
                        }
                    }
                }
                &-img {
                    width: 102px;
                    height: 102px;
                    margin: 5px auto;
                }
                &-tips {
                    font-size: 14px;
                    color: #666666;
                    line-height: 20px;
                    text-align: center;
                    width: 100%;
                }
            }
        }
    }
    .pics-lists {
        padding: 42px 10px 77px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        .case-pics {
            height: 197px;
        }
    }
    .coop-caseitemlists {
        padding: 30px 0 72px;
        justify-content: space-between;
    }
}
</style>